import { EnvironmentInjector, inject, runInInjectionContext } from '@angular/core';
import { Order } from '@datorama/akita';
import { addDays, isSameDay, isValid } from 'date-fns/esm';
import { merge } from 'lodash-es';
import { ComegoQuery, ComegoTime, ComegoTimeType, UserSettings } from 'timeghost-api';
import { coerceTimeFormat, hoursToFormat, resolveRawArgs } from './utils';
type CCSumBag = Record<ComegoTimeType, number>;
function getLastTime(user: UserSettings, date?: Date, type?: ComegoTimeType) {
  const comegoQuery = inject(ComegoQuery);
  if (!date || !isValid(date)) return null;
  const pastDay = addDays(date.getTime(), -1);
  const lastWorkingDayTime = comegoQuery.getAll({
    filterBy: (x) => isSameDay(new Date(x.start), pastDay) && x.user?.id === user.id && (!type || x.type === type),
    limitTo: 1,
    sortBy(a, b) {
      const aend = new Date(a.end || new Date()),
        bend = new Date(b.end || new Date());
      return aend.getTime() - bend.getTime();
    },
    sortByOrder: Order.DESC,
  })?.[0];
  if (!lastWorkingDayTime) return null;
  return {
    ...lastWorkingDayTime,
    start: new Date(lastWorkingDayTime.start),
    end: new Date(lastWorkingDayTime.end || new Date()),
  };
}
function getNextTime(user: UserSettings, date?: Date, type?: ComegoTimeType) {
  const comegoQuery = inject(ComegoQuery);
  if (!date || !isValid(date)) return null;
  const dayOfTime = addDays(date.getTime(), 1);
  const nextWorkingDayTime = comegoQuery.getAll({
    filterBy: (x) => isSameDay(new Date(x.start), dayOfTime) && x.user?.id === user.id && (!type || x.type === type),
    limitTo: 1,
    sortBy(a, b) {
      const atime = new Date(a.start || new Date()),
        btime = new Date(b.start || new Date());
      return atime.getTime() - btime.getTime();
    },
    sortByOrder: Order.ASC,
  })?.[0];
  if (!nextWorkingDayTime) return null;
  return {
    ...nextWorkingDayTime,
    start: new Date(nextWorkingDayTime.start),
    end: new Date(nextWorkingDayTime.end || new Date()),
  };
}
const typeToTranslateKey = {
  maxHours: 'errors.times.comego.dailyMaxHours-alert',
  break: 'errors.times.comego.breakRuleNotice-alert',
  rest: 'errors.times.comego.breakBetweenWorkingDays-alert',
};
export type CheckWorkingHourErrorsOptions = {
  prevDayRestEnabled: boolean;
  nextDayRestEnabled: boolean;
  breakBetweenDaysTypeFilter: ComegoTimeType;
  recordingTimeDiffDateFallback: boolean;
};
export function checkWorkingHourErrors(
  this: EnvironmentInjector,
  times: ComegoTime[],
  user: UserSettings,
  date?: Date,
  errorActAsWarning?: boolean,
  options?: Partial<CheckWorkingHourErrorsOptions>,
) {
  const wh = user?.workspace?.schedules?.workspace?.workinghours;
  if (!wh) return null;
  if (!date) date = new Date();
  options = merge(
    { nextDayRestEnabled: true, prevDayRestEnabled: true } as CheckWorkingHourErrorsOptions,
    options || {},
  );
  let type: string;
  errorActAsWarning = !!errorActAsWarning;
  const { work: sumValue, pause: pauseSum } = times.reduce(
    (acc, r) => {
      const end = (r.end && new Date(r.end)) || new Date(),
        start = new Date(r.start);
      acc[r.type] += end.getTime() - start.getTime();
      return acc;
    },
    { absence: 0, pause: 0, work: 0 } as CCSumBag,
  );
  const brokenRules = [];
  if (wh.preventDeviatingWorkingHours || errorActAsWarning) {
    if (typeof wh.dailyMaxHours === 'number' && sumValue > wh.dailyMaxHours * 3600 * 1000) {
      type = 'maxHours';
      const value = hoursToFormat(wh.dailyMaxHours);
      brokenRules.push({
        type,
        message: typeToTranslateKey[type],
        valueMs: wh.dailyMaxHours * 3600 * 1000,
        value,
        args: {
          dailyMaxHours: value,
        },
        errorActAsWarning,
      });
    }

    if (typeof wh.breakBetweenWorkingDays === 'number' && wh.breakBetweenWorkingDays > 0.0) {
      const wtimes = times
        ?.filter((x) => x.type !== 'pause')
        .sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime());
      const firstWork = wtimes?.[0];
      const lastWork = wtimes?.[wtimes.length - 1];
      const now = new Date();
      const tstart =
        (firstWork?.start && coerceTimeFormat(firstWork?.start, date)) || (isSameDay(date, now) ? now : null);
      const tend =
        (lastWork?.end && coerceTimeFormat(lastWork.end, date)) ||
        (isSameDay(date, now) ? now : options.recordingTimeDiffDateFallback ? now : null);
      if (tstart) {
        const lastWorkingDayTime =
          options.prevDayRestEnabled &&
          runInInjectionContext(this, () => getLastTime(user, date, options?.breakBetweenDaysTypeFilter));
        const nextWorkingDayTime =
          options.nextDayRestEnabled &&
          runInInjectionContext(this, () => getNextTime(user, date, options?.breakBetweenDaysTypeFilter));
        const minBreakBetweenDay = wh.breakBetweenWorkingDays * 3600 * 1000;
        if (
          (lastWorkingDayTime?.end &&
            tstart.getTime() - new Date(lastWorkingDayTime.end ?? tstart.getTime()).getTime() < minBreakBetweenDay) ||
          (nextWorkingDayTime?.start &&
            new Date(nextWorkingDayTime.start ?? tend.getTime()).getTime() - tend.getTime() < minBreakBetweenDay)
        ) {
          type = 'rest';
          const value = hoursToFormat(wh.breakBetweenWorkingDays);
          brokenRules.push({
            type,
            message: typeToTranslateKey[type],
            value,
            errorActAsWarning,
            args: {
              breakBetweenWorkingDays: value,
            },
          });
        }
      }
    }
    if (wh.breaks?.length) {
      const breakRule = wh.breaks
        .slice()
        .sort((b, a) => a.workingHours - b.workingHours)
        .find((x) => {
          const workingHourMs = x.workingHours * 3600 * 1000;
          const pauseHourMs = x.minutesDue * 3600 * 1000;
          return workingHourMs < sumValue && pauseHourMs > pauseSum;
        });
      if (breakRule) {
        type = 'break';
        const rawArgs = resolveRawArgs(breakRule);
        brokenRules.push({
          ...rawArgs,
          type,
          message: typeToTranslateKey[type],
          value: sumValue,
          rule: breakRule,
          args: {
            ...rawArgs,
          },
          errorActAsWarning,
        });
      }
    }
  }
  return brokenRules;
}
